import React, { useState } from "react";
import axios from "axios";
import "./App.css";
import confetti from "canvas-confetti";
import styled from 'styled-components';
import { VscGithub } from 'react-icons/vsc';
import ballerineLogo from './ballerine_logo.png'
const Button = styled.button`
    background-color: black;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: darkgrey;
    }

    img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
    }
`;

const StarButton = () => (
  <Button onClick={() => window.open('https://github.com/ballerine-io/ballerine', '_blank')}>
    <VscGithub />
    <span style={{ paddingLeft: "10px", fontWeight: 700 }}> Star Us on GitHub!</span>
  </Button>
);

function App() {
  const [name1, setName1] = useState("");
  const [name2, setName2] = useState("");
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleMatchNames = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/match-names`, {
        name1,
        name2,
      });
      setResult(response.data);
      if (response.data.isSamePerson) {
        showConfetti();
      }
    } catch (error) {
      console.error(error);
      setResult({
        isSamePerson: false,
        explanation: "Error: Unable to determine if the names are the same.",
      });
    }
    setLoading(false);
  };

  const showConfetti = () => {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 },
    });
  };

  return (
    <div className="App">
      <div style={{ padding: "20px" }}>
        <img src={ballerineLogo}></img>
      </div>
      <div className="container">
        <div>
          <h1>Multilingual Name Matcher</h1>
          <div className="input-container">
            <input
              type="text"
              value={name1}
              onChange={(e) => setName1(e.target.value)}
              placeholder="Name 1"
              className="input-field"
            />
            <input
              type="text"
              value={name2}
              onChange={(e) => setName2(e.target.value)}
              placeholder="Name 2"
              className="input-field"
            />
          </div>
          <button onClick={handleMatchNames} className="button" disabled={loading}>
            {loading ? "Loading..." : "Match Names"}
          </button>
          {result && (
            <div className="result-container">
              <h2>Result:</h2>
              <p>Is it the same person: {result.isSamePerson ? 'Yes' : 'No'}. {result.isSamePerson && <span role="img" aria-label="Dancer">💃</span>}</p>
              <p>{result.explanation}</p>
            </div>
          )}
        </div>

      </div>
      <div style={{ padding: "15px" }}><StarButton /></div>
      <div ><iframe src="https://www.loom.com/embed/d44eb49b6cda41228d63b8d979ac4359" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen ></iframe></div>
    </div>
  );
}

export default App;
